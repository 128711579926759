import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)
  const annaImage = getImage(data.annaImage)
  const markImage = getImage(data.markImage)
  const blogImage = getImage(data.blogImage)
  const blogImage2 = getImage(data.blogImage2)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Rólunk'
            subheader='Úgy gondoljuk, hogy a társasjátékok ma egyedülálló közösségi élményt nyújtanak, mert interaktívak, gondolkodósak, sokat kell bennük kommunikálni és felejthetetlen pillanatokat élhetünk át bennük, együtt. Eddig személyesen hirdettük az „igét”, barátok és ismerősök körében, most már a blog is a segítségünkre van: jusson el a társasjátékozás öröme mindenkihez! 😍'
          />
          <Divider />

          <Box sx={styles.imageWrapper}>
            <Img image={image} />
          </Box>
          <Divider />

          <Section title='Mi a célunk a blogírással?'>
            <Text variant='p'>
              Célunk, hogy megismertessük a társasjátékokat az olvasóinkkal a saját szemszögünkből. Nem a változatos szavakkal megfogalmazott dicséret a cél  - szeretjük leírni mi az, ami a játékot egyedivé teszi, azonban ki szoktuk emelni azt is, amitől jobb lehetne a játék, néha javaslunk házi szabályokat is. Szerintünk nagyon elkél manapság ez a content, hiszen a szponzorált videókban minden játékot magasztalnak, ám sokszor nem emelik ki, hogy pontosan kinek is való, milyen érdeklődésű, korú, mennyi tapasztalattal rendelkező társaságnak ajánlják a játékokat. A célunk, hogy ebben is kicsit irányt tudjunk mutatni Nektek.
            </Text>
            <Text variant='p'>
              További, inkább személyes célunk, hogy minél több jó játékot próbáljunk ki. Nem mondanánk, hogy fókuszban az új megjelenések állnak – hiszen a korábbi klasszikusok, vagy akár „hidden gemek” ugyanúgy megérdemlik a figyelmet (talán még jobban is), mint az új társasok. Ezt a törekvést ültettük bele a (le)játszási listákba, amelyek tematikusan csokorba fogják az általunk kipróbált társasjátékokat, rövid ismertető szöveggel.
            </Text>
          </Section>
          <Divider />

          <Flex sx={styles.grid}>
            <Box sx={styles.column}>

              <Section>
                <Card variant='paper'>
                  <Img image={annaImage} />
                </Card>
              </Section>

              <Divider />
              <Section title='Anna'>
              </Section>

              <Section variant='paper'>
              <Text variant='p'>
                A társasjátékozás mindig is az életem részét képezte, már egészen kis koromtól kezdve. Kártyajátékokkal indult a dolog, hiszen alapvetően kártyás családban nőttem fel. Az első nagy kedvenc társasjátékom a Catan volt, és ez a korszak egészen sokáig tartott. Aztán felkerültem Budapestre egyetemistaként, és barátokon keresztül eljutottam a Müszibe (Játszóház Projekt), ahol rengeteg társasjáték várt arra, hogy kipróbáljam. Ekkor kezdtem jobban érdeklődni a modern társasjátékok iránt.
                
              </Text>
              <Text variant='p'>
                Ma már a játékszabályokat én szoktam tanítani a baráti összejöveteleken, amit nagyon élvezek. Szerencsére olyan baráti társaságom van, akik szintén lelkesednek a társasjátékokért, így a heti egy közös partit nem csak én várom. Gyakran ülök le egyébként egymagamban is játszani, mert szórakoztat, és szellemi felfrissülést jelent számomra. Nagy álmom, hogy egyszer összerakom a saját társasjátékomat, amit a szakmámból adódóan szeretnék valami gyógyszeriparhoz kapcsolódó tematikával ellátni.
              </Text>
              </Section>
              <Divider />
            

            </Box>
            <Box sx={styles.column}>

              <Section>
                <Card variant='paper'>
                  <Img image={markImage} />
                </Card>
              </Section>

              <Divider />

              <Section title='Márk'>
              </Section>

              <Section variant='paper'>
                <Text variant='p'>
                Egykeként gyerekkoromban nem voltam nagy társasjátékos. Csak az akkoriban menő Activityt, Twistert, és Monopolyt próbáltam ki, de nem igazán élveztem egyiket se. Ha tudtam volna, hogy léteznek mélyebb stratégiai játékok is (igen, a Catan), akkor azokat nyűttem volna és manapság talán gyakrabban győzném le Annát. 🙄
                Így a társasok helyett az informatika és fotózás lett a hobbim. Persze a fotózás csupán virág fotók és tájképek készítésében merült ki. Ugye milyen izgalmasan hangzik? 🤔 A nyüzsgő városi fotók és a portrék készítésétől mindig is tartottam, így maradtak a statikus témák. Ezért tetszett meg a társasok fotózása is: gyönyörű színes grafikák, könnyen beállítható, variálható háttér és téma.
                </Text>

                <Text variant='p'>
                A társasokat Anna kedveltette meg velem, az első nagy szerelem a 7 Csoda volt, amit az Azul és a Five Tribes követett. Azóta már sok játékot tudtunk kipróbálni a könnyed családi társasoktól egészen az összetett stratégiai eurókig.
                </Text>
              </Section>


              <Divider />
            </Box>
          </Flex>

         

          <Section title='Mi van a polcunkon?'>
          <Text variant='p'>
          Ha megnézitek a blogon a gyűjteményünket (a kiegészítőket nem jelöltük), akkor bizony láthatjátok, hogy nem vagyunk gyűjtők. Nálunk az új játék vásárlás egy igen kényes kérdés, mert tényleg csak olyan játékot veszünk meg, amiről sejtjük, hogy sokat elő is fog kerülni utána. Később, ha azt látjuk, hogy mégsem vesszük le a polcról ezeket, eladásra kínáljuk őket. E mögött egy kicsit környezetvédelmi megfontolások is vannak, egyszerűen nem akarunk olyan termékeket felhalmozni a lakásban, amelyeknek nincs funkciója (még ha nagyon szépen is mutatnak a polcon, és mondjuk évi egyszer lekerülnek onnan). Nagy öröm számunkra, hogy manapság már rengeteg helyről lehet társasjátékot kölcsönözni, így ha épp egy olyan darabbal a játszanánk, ami nincs meg a polcunkon, gond nélkül be tudjuk szerezni néhány partira.
          
          </Text>
          </Section>
          <Divider />

          <Box sx={styles.imageWrapper}>
            <Img image={blogImage2} />
          </Box>
          <Divider />

          <Section title='Honnan kapjuk a játékokat?'>
          <Text variant='p'>
          2019-ben már egy ideje a Társasnavigátortól kölcsönöztünk játékokat, amikor megjött a kedvünk a blogoláshoz. Roland nagy örömmel vette az ötletünket, hogy továbbra is tőle hozhassunk játékokat kipróbálásra. Jó néhány poszt után, nagy meglepetésünkre megkeresett minket a Gémklub, és felajánlották, hogy ők is adnának szívesen játékokat kipróbálásra. Ennek azért is örültünk, mert míg a Társasnavigátornál nagy étvággyal faltuk a klasszikusokat, így már a friss megjelenésű játékokat is az elsők között nyílt alkalmunk kipróbálni.
          </Text>
          <Text variant='p'>
          2020-ban a Játszóház Projekt elindította a Játszó/Házhoz kölcsönző szolgáltatást, amit a velük való egyeztetés után nagy lelkesedéssel próbáltunk mi is ki, és mutattunk be nektek egy cikkben. 2021-ben pedig a Társasjátékpontot üzemeltető Danival és Melindával is megismerkedtünk, akik a ritkább, kevésbé ismert játékokra is rendszeresen hívják fel a figyelmünket.
          </Text></Section>
          <Divider />
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/DSC_9872.jpg/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          height: 800
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    annaImage: file(absolutePath: { regex: "/Vincze-Anna.jpg/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 533
          height: 800
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    markImage: file(absolutePath: { regex: "/Melisek-Mark.jpg/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 533
          height: 800
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    blogImage: file(absolutePath: { regex: "/tarsasjatek-ajanlo-blog.jpg/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          height: 665
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    blogImage2: file(absolutePath: { regex: "/tarsasjatek-ajanlo-blog-4.jpg/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          height: 667
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
